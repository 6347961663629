import './App.css';
import React from 'react';
import { gql } from 'apollo-boost';
import { Query } from 'react-apollo';
import moment from 'moment';
import styled from '@emotion/styled'
import Web3 from 'web3'
import { ethers } from 'ethers';

const Warning = styled.div({
  background:'#c93838',
  margin:'0.5em',
  padding:'0.5em'
})

const H1 = styled.h1({
  wordSpacing:'-10px'
})


const GET_PARTICIPATION = gql`
  query {
    depositEntities(orderBy:timestamp, orderDirection:desc, first:20){
      id
      sender
      value
      message
      timestamp
    }
  }
`
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '', message: '', web3:null, account:null,
      destination:null, ethers:null, donners:{}, chainId:'',
      readonly:null
    };
    let infuraProvider = new ethers.providers.InfuraProvider('mainnet');
    this.state.readonly = infuraProvider
    if (window.ethereum) {
      var provider = new Web3(window.ethereum);
      var ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
      this.state.web3 = provider
      this.state.ethers = ethersProvider
      ethersProvider.resolveName('happywedding.julietandnick.eth').then((destination)=>{
        if(destination){
          this.setState({destination})
        }
      })
      ethersProvider.getNetwork().then((r)=>{
        this.setState({chainId:r.chainId})
      })

      try {
        // Request account access if needed
        window.ethereum.enable().then((accounts)=>{
          this.setState({account: accounts[0]});
        })
        // Acccounts now exposed
      } catch (error) {
        // User denied account access...
      }  
    }else{
      alert("Please connect to Metamask or web3 browser")
    }


    this.handleValue = this.handleValue.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleValue(event) {
    this.setState({value: event.target.value});
  }

  handleMessage(event) {
    this.setState({message: event.target.value});
  }

  handleSubmit(event) {
    const web3 = this.state.web3
    console.log('this.state', this.state)
    const message = web3.utils.utf8ToHex(this.state.message)
    if(!this.state.destination){
      console.warn('Problem fetching Ethereum address')
    }
      const value = web3.utils.toWei(this.state.value, 'ether');
    try {
      // Request account access if needed
        this.state.web3.eth.sendTransaction({
          from:this.state.account,
          to: (this.state.destination),
          value:value,
          data:message
        });
      // Acccounts now exposed
    } catch (error) {
      alert(error)
      // User denied account access...
    }
    event.preventDefault();
  }

  render() {
    let warning
    const web3 = new Web3();
    console.log('state', this.state)
    if(this.state.chainId !== 1){
      warning = 'To gift ETH with a message, please connect to Mainnet'
    }
    return (
      <div className="App">
        <header className="App-header">
          {this.state.network}
          <H1>🤵HappyWedding .JulietAndNick .eth 👰</H1>
          <>
          <h2>Gifts and messages</h2>
          <Query query={GET_PARTICIPATION}>
            {({ loading, error, data}) => {
              if (loading) return <div>Loading...</div>;
              if (error) return <div>Error :(</div>;
                const listItems = data.depositEntities.map((d) => {
                  const value = d.value / (10 ** 18)
                  const date = moment(new Date(parseInt(d.timestamp) * 1000)).fromNow();
                  this.state.readonly.lookupAddress(d.sender).then((ensName)=>{
                    if(ensName){
                      let donners = this.state.donners
                      donners[d.sender] = ensName
                      this.setState({donners})
                    }
                  })
                  const donner = this.state.donners[d.sender]
                  const message = d.message !== '0x' ? `with message "${web3.utils.hexToUtf8(d.message)}"` : '';
                  const url = `https://etherscan.io/tx/${d.id}`
                  const link = (<a href={url}>{date}</a>)
                  return(
                    <tr><td>{donner || d.sender.slice(0, 5) + '...'} gifted {value} ETH {message}  {link}  </td></tr>
                  )                
                }
                );
              return (
                <table>
                  {listItems}
                </table>
              )
            }}
          </Query>
            {warning? (
              <Warning>{warning}</Warning>
            ): (
              <form onSubmit={this.handleSubmit}>
                <h2>Gift your own</h2>
                <label>
                  Add a message to Juliet and Nick
                </label>
                <br/>
                <br/>
                <textarea cols={50} rows={5} value={this.state.message} onChange={this.handleMessage} />
                <br/>
                <br/>
                <label>
                  Gift some ETH (eg: 0.05)
                </label>
                <br/>
                <input type="text" value={this.state.value} onChange={this.handleValue} />
                <br/>
                <input type="submit" value="Submit" />
              </form> 
            )}
          </> 
        </header>
      </div>
    );
  }  
}

export default App;
